// Add your custom JS here.

(function ($) {
    $(document).ready(function () {

    	$('#secondary-menu .menu-item').hover(
    		function(){ $('li.menu-item span').toggle() }
       		// function(){ $('li.menu-item span').addClass('d-block') },
       		// function(){ $('li.menu-item span').removeClass('d-none') }
		);

		//Front page quotes - height 
        $('.frontpage-quotes .quote:gt(0)').fadeTo(0, 0);
        var highestBox = 0;
        // Select and loop the elements you want to equalise
        $('.frontpage-quotes .quote', this).each(function(){
            // If this box is higher than the cached highest then store it
            if($(this).height() > highestBox) {
                highestBox = $(this).height();
            }
        });

        $('.frontpage-quotes',this).height(highestBox + 60);
		
		//Front page quotes - loop interval
        setInterval(function () {
            $('.frontpage-quotes > :first-child').fadeTo(0, 0).next('.quote').fadeTo('slow', 1).end().appendTo('.frontpage-quotes');
        }, 10000);

        /* Search Menu */
	
	// $(".search-icon").click(function() {
	// 	$(".navsearchform").show();
	// });

	// $(document).keyup(function(e) {
	// 	// Ref https://stackoverflow.com/questions/3369593/how-to-detect-escape-key-press-with-pure-js-or-jquery
	// 	// Close search if esc key pressed
	// 	if (e.key == "Escape") {
	// 	  $(".navsearchform").hide();
	// 	}
	// });

  

    });

})(jQuery);
